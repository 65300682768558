import './App.css';
import './Slide.css';
import profile from './images/1.jpg';
import ImageSlider from'./Slider';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import React, {useEffect, useState} from 'react';
import Web3 from 'web3';
import Contract from './contracts/MyNFT.json';
import {connect,connectContract,mint} from './Web3Methods';
import {FaTwitter} from "react-icons/fa";
import {IconContext} from "react-icons";


function App() {
  const [quantity,setQuantity] = useState(1);
  const [account,setAccount] = useState();
  const [contract,setContract] = useState();
  const [connected,setConnected] = useState(false);
  const [disable,setDisabled] = useState(false);
  
  useEffect(()=>{
    if(window.ethereum !== undefined){
    window.ethereum.on('accountsChanged',(accounts)=>{
      setAccount(accounts[0]);
    })
    return () =>{
      window.ethereum.removeListener('accountsChanged',(accounts) =>{
        setAccount(accounts[0]);
      })
    }
  }
  });
  useEffect(() =>{
  },[account]);

  function mintButton(){
    setDisabled(true);
    let provider = window.ethereum;
    const web3 = new Web3(provider);
    let myValue = quantity * 0.1;
    contract.methods.mint(account,quantity).send({from:account,value:web3.utils.toWei(`${myValue}`,'ether')}).then((result) =>{
      setDisabled(false);
    }).catch((error)=>{
      if(error.code === 4001){
      }else{
        console.log(error);
      }
      setDisabled(false);
    })
  }

  async function connectButton(){
    let currentAccount;
    if(account === undefined){
      currentAccount = await connect();
      setAccount(currentAccount);
    }
    let myContract;
    if(contract === undefined){
      myContract = await connectContract(Contract);
      setContract(myContract);
      setConnected(true);
    }
  }

  function sliderChange(event,newValue){
    setQuantity(newValue);
  }
  
  return (
    <div className="App">
      
      <div className="App-header">
        <div className="Greeting">
          <div className="Greeting-cont">
            {/* <h3>EFNINE</h3>
          <p>Coming soon...</p> */}
          <p>EFNINE</p>
          {connected ? <Slider value={quantity} min={1} max={5} onChange={sliderChange} color="secondary" aria-label="Default" valueLabelDisplay="auto" /> : null}
          {connected ? <Button disabled={disable ? true:false}variant="contained" onClick ={mintButton}>{disable? 'Busy' : 'Mint'}</Button>: <Button variant="contained" onClick ={connectButton}>Connect wallet</Button>} 
          </div>
        </div>
        <div className="Art-panel">
          <div className="Art-panel-cont">
            <ImageSlider/>
          </div>
        </div>
        
      </div>
      <div className="About">
        <div className="About-paragraph">
        <h2>About</h2>
        <p>
          EFNINE is a NFT collection of abstract art, where each piece is created uniquely to itself.
          While there is no certain theme, the value of EFNINE lies on the appreciator of the art.
          There are only 100 pieces made.
        </p>
        </div>
      </div>
      <div className="Team">
        <h1>Team</h1>
        <div className="Team-profile">
          <h3>0xBenny | Founder</h3>
          <img src={profile}></img>
        </div>
      </div>
      <div className="Header">
        <IconContext.Provider value={{ color: "blue", size: "2em" }}>
        <div>
          <a href="https://twitter.com/EFNINE_NFT"><FaTwitter /></a>
        </div>
        </IconContext.Provider>
      </div>
      
      
    </div>
  );
}

export default App;
