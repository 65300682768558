import Web3 from 'web3';

let contractAddres = '0x2D67C038fc1E280d0a972666E7482dBE41E2F1E5';
export async function connect(){
    let provider = window.ethereum;
    if(provider !== undefined){
    }
    let accounts = await provider.request({method:"eth_requestAccounts"});
    return accounts[0]; 
   }
export async function connectContract(Contract){
    let provider = window.ethereum;
    const web3 = new Web3(provider);
    const networkId = await web3.eth.net.getId();
    const contract = new web3.eth.Contract(Contract.abi,contractAddres);
    return contract;   
}
export function mint(contract,account,quantity){
    let provider = window.ethereum;
    const web3 = new Web3(provider);
    let myValue = quantity * 0.01;
    contract.methods.mint(account,quantity).send({from:account,value:web3.utils.toWei(`${myValue}`,'ether')}).then((result) =>{
      console.log('Minted sucessfully');
    }).catch((error)=>{
      if(error.code === 4001){
        console.log('Please connect to metamask');
      }else{
        console.log(error);
      }
    }) 
}

