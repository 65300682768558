import './Slide.css';
import image2 from './images/2.jpg';
import image3 from './images/3.jpg';
import image4 from './images/4.jpg';
import image5 from './images/5.jpg';
import image6 from './images/6.jpg';
import image7 from './images/7.jpg';
import React, {useState,useEffect} from 'react';


function Slider() {
  const [count,setCount] = useState(1);
  
  useEffect(()=>{
      const id = setInterval(buttonClick,3000);
      return () => clearInterval(id);
  },[count]);
  function buttonClick(){
    let slide = document.getElementsByClassName("slides");
    if(count >= 5){
      slide[0].style.transform = `translateX(${-500 * 0}px)`
      setCount(1);
    }else{
      slide[0].style.transform = `translateX(${-500 * count}px)`
      setCount(count+1);
    }
    
  }
  return (
      <div className="container" onLoad={buttonClick}>
        <div className="slides">
          <div className="div1">
              <img src={image2}></img>
          </div>
          <div className="div2">
            <img src={image3}></img>
          </div>
          <div className="div3">
            <img src={image4}></img>
          </div>
          <div className="div4">
            <img src={image5}></img>
          </div>
          <div className="div5">
            <img src={image6}></img>
          </div>
          <div className="div6">
            <img src={image7}></img>
          </div>
        </div>
      </div>

  );
}

export default Slider;
